<template>
  <div>
    
    <b-modal
      id="modal-offerProduct"
      ref="modal1"
      size="lg"
      title="Select Offer  Products"
    >
      <div class="card-title">
        <div class="row pb-2 mb-1">
          <div class="col-md-5 col-12">
            <form @submit.prevent="sortByPriceRange">
              <div class="row">
                <div class="col-4 col-md-4 pr-0">
                  <input
                    type="text"
                    v-model="priceRange.price_from"
                    class="form-control pr-1 pl-2"
                    placeholder="Price from"
                  />
                </div>
                <div class="col-4 col-md-4 pr-0">
                  <input
                    type="text"
                    v-model="priceRange.price_to"
                    class="form-control pr-1 pl-2"
                    placeholder="Price To"
                  />
                </div>
                <div class="col-4 col-md-4">
                  <button class="btn btn btn-add btn-dark filter-btn">
                    <i class="mdi mdi-magnify" style="font-size: 22px !important"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="col-md-5 offset-md-2 col-12">
            <form @submit.prevent="sortByIdRange">
              <div class="row">
                <div class="col-4 col-md-4 pr-0">
                  <input
                    type="text"
                    v-model="idRange.id_from"
                    class="form-control pr-1 pl-2"
                    placeholder="ID from"
                  />
                </div>
                <div class="col-4 col-md-4 pr-0">
                  <input
                    type="text"
                    v-model="idRange.id_to"
                    class="form-control pr-1 pl-2"
                    placeholder="ID To"
                  />
                </div>
                <div class="col-4 col-md-4">
                  <button class="btn btn btn-add btn-dark filter-btn">
                    <i class="mdi mdi-magnify" style="font-size: 22px !important"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-5">
            <div class="d-flex align-items-center input-group search-field">
              <div class="input-group-prepend bg-transparent">
                <i class="input-group-text border-0 mdi mdi-magnify"></i>
              </div>
              <input
                v-model="searchKey"
                type="text"
                v-on:keyup="search()"
                class="form-control bg-transparent border-0 search-border-left"
                placeholder="Search...."
              />
            </div>
          </div>
          <div class="col-md-5 offset-md-2 col-12">
            <div class="row">
              <div class="col-4 col-md-4 pr-0">
                <select
                  class="custom-select form-control"
                  @change="sortByStatus"
                  v-model="status"
                >
                  <option value="" selected>All Statuses</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>
              <div class="col-4 col-md-4 pr-0">
                <select
                  class="custom-select form-control"
                  @change="sortByBrands"
                  v-model="brand"
                >
                  <option value="" selected>All Brands</option>
                  <option
                    v-for="brandsList in brandsList"
                    :key="brandsList.id"
                    v-bind:value="brandsList.id"
                  >
                    {{ brandsList.name }}
                  </option>
                </select>
              </div>
              <div class="col-4 col-md-4">
                <button
                  class="btn btn btn-add btn-primary filter-btn"
                  @click="reload"
                  v-b-tooltip.hover
                      title="Clear"
                >
                  <i class="mdi mdi-reload" style="font-size: 22px !important"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <form @submit.prevent="offerProductId">
        <div class="table-responsive mb-0">
          <table class="table border table-centered table-nowrap">
            <thead>
              <tr>
                <th>Select</th>
                <th>Image</th>
                <th
                  @click="sort('name')"
                  class="defult"
                  v-bind:class="[sortBy === 'name' ? sortDirection : '']"
                >
                  Name
                </th>
                <th
                  @click="sort('active')"
                  class="defult"
                  v-bind:class="[sortBy === 'active' ? sortDirection : '']"
                >
                  Status &nbsp;
                </th>
                <th
                  @click="sort('brand_id')"
                  class="defult"
                  v-bind:class="[sortBy === 'brand_id' ? sortDirection : '']"
                >
                  Brand
                </th>
                <!-- <th>Categories</th> -->
                <th
                  @click="sort('price')"
                  class="defult"
                  v-bind:class="[sortBy === 'price' ? sortDirection : '']"
                >
                  Price
                </th>
              </tr>
            </thead>
            <tbody v-if="products">
              <tr v-for="product in products" :key="product.id">
                <td>
                  <div class="form-check mt-0">
                    <label class="form-check-label text-muted">
                      <input
                        type="checkbox"
                        v-model="selectedId"
                        :value="product"
                        :id="product.id"
                        class="form-check-input form-group"
                      />
                      <i class="input-helper"></i>
                    </label>
                  </div>
                </td>
                <td class="pt-1 pb-1">
                  <span v-if="product.images[0]">
                      <img v-if="product.images[0].thumb_s" :src="`${imageApiURL}/${product.images[0].thumb_s}`"
                        class="profile-img img-lg" />
                      <img v-else src="@/assets/images/dummy.jpg" alt="" class="profile-img img-lg" /></span>
                      <span v-else>
                      <img src="@/assets/images/dummy.jpg" alt="aaa" class="profile-img img-lg" />
                    </span>
                  </td>
                <td>{{ product.name }}</td>
                <td>
                  <label class="switch">
                    <input type="checkbox" v-model="product.active" />
                    <span class="slider round"></span>
                  </label>
                </td>
                <td>
                  <span v-if="product.brands && product.brands.name">{{
                    product.brands.name
                  }}</span>
                  <span v-else-if="product.brand_id">{{ product.brand_id }}</span>
                </td>
                <td>
                  {{
                    product.price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-12 p-3 border text-center" v-if="products == ''">
            No data found!
          </div>
          <div class=" " style="border-top: 1px solid #e9ecef">
            <paginate  :page-count="totalPage" :page-range="3" :margin-pages="1"
              :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination float-left mb-0'"
              :page-class="'page-item'">
            </paginate>
          </div>
          <div class="form-group float-right mt-2 mb-0">
            <button class="btn btn btn-add btn-primary">Upload</button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { catalogApi } from "../api";

const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import Paginate from 'vuejs-paginate'
export default {
  name: "offerProduct",
  components: { Paginate },
  data() {
    return {
      searchKey: "",
      imageApiURL,
      sortBy: "name",
      sortDirection: "",
      submitted: false,
      isSubmitted: false,
      brandsList: "",
      
      totalPage: this.totalPage,
      brand: "",
      status: "",
      priceRange: {
        price_from: "",
        price_to: "",
      },
      idRange: {
        id_from: "",
        id_to: "",
      },
      products: this.edit,
      selleceted: [],
      selectedId: [],
    };
  },
  validations: {
    priceRange: {
      price_from: { required },
      price_to: { required },
    },
    idRange: {
      id_from: { required },
      id_to: { required },
    },
  },
  mounted() {
    let page = 1;
    this.getBrandsByList();
    this.getProducts(page);
  },
  methods: {

    offerProductId() {
      this.$refs.modal1.hide();
      this.$emit("offerProductId", this.selectedId);
    },

    async reload(page) {
      this.status = "";
      this.brand = "";
      this.priceRange.price_from = "";
      this.priceRange.price_to = "";
      this.idRange.id_from = "", 
      this.idRange.id_to = "", 
      page = 1;
      
      const { data } = await catalogApi.getProducts(page);
      this.products = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
    },

    // Product List

    async getProducts(page) {
      
      const { data } = await catalogApi.getProducts(page);
      this.products = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
    },

    async sort(s, page) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;
      var parameter = {
        name: this.searchKey,
        sort_column: this.sortBy,
        sort_type: this.sortDirection,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var sortParam = new URLSearchParams(parameter).toString();
      page = 1;
      
      const { data } = await catalogApi.sortDirection(sortParam, page);
      this.products = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
    },

    clickCallback: function (page) {
      this.getProducts(page) 
    },

    async getBrandsByList() {
      const { data } = await catalogApi.getBrandsByList();
      this.brandsList = data.data.data;
    },

    // Filter By  Product

    async sortByBrands(page) {
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var brandParam = new URLSearchParams(parameter).toString();
      page = 1;
      
      const { data } = await catalogApi.sortByBrands(brandParam, page);
      this.products = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
    },

    async sortByStatus(page) {
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var statusParam = new URLSearchParams(parameter).toString();

      page = 1;
      
      const { data } = await catalogApi.sortByStatus(statusParam, page);
      this.products = data.data.data.data;
      
    },

    async sortByPriceRange(page) {
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var rangeParam = new URLSearchParams(parameter).toString();

      page = 1;
      
      const { data } = await catalogApi.priceRange(rangeParam, page);
      this.products = data.data.data.data;
      
    },

    async sortByIdRange(page) {
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var idParam = new URLSearchParams(parameter).toString();
      page = 1;
      
      const { data } = await catalogApi.idRange(idParam, page);
      this.products = data.data.data.data;
      
    },

    async sortByBrands(page) {
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var brandParam = new URLSearchParams(parameter).toString();
      page = 1;
      
      const { data } = await catalogApi.sortByBrands(brandParam, page);
      this.products = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
    },


    async search(page) {
      var parameter = {
        name: this.searchKey,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var searchParam = new URLSearchParams(parameter).toString();
      page = 1;

      
      const { data } = await catalogApi.productSearch(searchParam, page);
      this.products = data.data.data.data;
      
    },
  },
};
</script>
<style scoped>
.productsOffer {
  display: flex;
  justify-content: center;
}
.defult {
  background-image: url(../assets/images/download.svg);
  cursor: pointer;
  background-position: right 0.46875rem center;
  background-repeat: no-repeat;
  background-size: 0.65em 1em;
}
.desc {
  background-image: url(../assets/images/desc.svg);
}
.asc {
  background-image: url(../assets/images/asc.svg);
}
.table th,
.table td {
  border-right: 1px solid #f6f6f7;
}
</style>
