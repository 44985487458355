<template>
  <section class="product">
    
    <div class="page-header">
      <h3 class="page-title"> Products </h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <a class="btn btn btn-add btn-success" @click="downloadFile()">Export</a>
          <router-link class="btn btn btn-add btn-primary" :to="`/catalog/add-product`">Add New
          </router-link>
        </div>
      </nav>
    </div>
    
    <div class="card">
      <div class="card-title">
        <div class="row">

          <div class="col-md-3 col-12 filter-pr-0">
            <form @submit.prevent="sortByPriceRange">
              <div class="row">
                <div class="col-4 col-md-4 pr-0 pl-3">
                  <input type="text" v-model="priceRange.price_from" class="form-control pr-1 pl-2"
                    placeholder="Price from" />
                </div>
                <div class="col-4 col-md-4 pr-2 pl-2">
                  <input type="text" v-model="priceRange.price_to" class="form-control pr-1 pl-2"
                    placeholder="Price To" />
                </div>
                <div class="col-4 col-md-4 pl-0">
                  <button class="btn btn btn-add btn-dark filter-btn">
                    <i class="mdi mdi-magnify" style="font-size: 22px !important"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div class="col-6 col-md-2 pl-3 pr-2">
            <select class="custom-select form-control" @change="sortByStatus" v-model="status">
              <option value="" selected>All Statuses</option>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
          </div>

          <div class="col-6 col-md-2 pl-2 pr-3">
            <select class="custom-select form-control" @change="sortByBrands" v-model="brand">
              <option value="" selected>All Brands</option>
              <option v-for="brandsList in brandsList" :key="brandsList.id" v-bind:value="brandsList.id">
                {{ brandsList.name }}
              </option>
            </select>
          </div>

          <div class="col-4 col-md-2 col-0 pl-2"></div>

          <div class="col-md-3 col-12 filter-pl-0">
            <form @submit.prevent="sortByIdRange">
              <div class="row">
                <div class="col-4 col-md-4 pr-0">
                  <input type="text" v-model="idRange.id_from" class="form-control pr-1 pl-2" placeholder="ID from" />
                </div>
                <div class="col-4 col-md-4 pr-2 pl-2">
                  <input type="text" v-model="idRange.id_to" class="form-control pr-1 pl-2" placeholder="ID To" />
                </div>
                <div class="col-4 col-md-4 pl-0 pr-3">
                  <button class="btn btn btn-add btn-dark filter-btn">
                    <i class="mdi mdi-magnify" style="font-size: 22px !important"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
      <div class="table-responsive mb-0">
        <table class="table table-centered table-nowrap">
          <thead>
            <tr>
              <th @click="sort('name')" class="defult" v-bind:class="[sortBy === 'name' ? sortDirection : '']">
                Name
              </th>
              <th @click="sort('active')" class="defult" v-bind:class="[sortBy === 'active' ? sortDirection : '']">
                Status &nbsp;
              </th>
              <th @click="sort('brand_id')" class="defult" v-bind:class="[sortBy === 'brand_id' ? sortDirection : '']">
                Brand
              </th>
              <th @click="sort('price')" class="defult" v-bind:class="[sortBy === 'price' ? sortDirection : '']">
                Price
              </th>
              <th style="border-right: 0px">Action</th>
            </tr>
          </thead>

          <tbody v-if="products">
            <tr v-for="product in products" :key="product.id">
              <td>{{ product.name }}</td>
              <td>
                <label class="switch">
                  <input type="checkbox" @change="onToggleChange(product.id, product.active)" v-model="product.active" />
                  <span class="slider round"></span> </label><br /><br />
              </td>
              <td>
                <span v-if="product.brands && product.brands.name">{{
                  product.brands.name
                }}</span>
                <span v-else-if="product.brand_id">{{ product.brand_id }}</span>
              </td>
              <td>
                {{
                  product.price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
              <td style="border-right: 0px">
                <router-link class="mr-2 table-icon" v-b-tooltip.hover title="Edit"
                  :to="`/catalog/edit-product/${product.id}`">
                  <i class="mdi mdi-pencil"></i>
                </router-link>
                <!-- <a v-on:click="deleteProduct(product.id)" class="table-icon" v-b-tooltip.hover title="Delete">
                  <i class="mdi mdi-delete"></i>
                </a> -->
              </td>
            </tr>
          </tbody>
        </table>

        <div class="col-12 p-3 text-center" v-if="products == ''">No data found!</div>

        <div style="border-top: 1px solid #e9ecef" v-if="products">
          <paginate :page-count="totalPage" :page-range="3" :margin-pages="2" :click-handler="clickCallback"
            :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'" :page-class="'page-item'">
          </paginate>
        </div>

      </div>
    </div>

  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { catalogApi } from "../../../api";

import Paginate from 'vuejs-paginate'
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
export default {
  name: "view_brand",
  edit: {
    active: Boolean,
  },
  
  components: {
     Paginate,
  },
  
  data() {
    return {
      searchKey: "",
      imageApiURL,
      sortBy: "name",
      sortDirection: "",
      submitted: false,
      isSubmitted: false,
      brandsList: "",
      
      totalPage: this.totalPage,
      brand: "",
      status: "",
      page: 10,
      priceRange: {
        price_from: "",
        price_to: "",
      },
      idRange: {
        id_from: "",
        id_to: "",
      },
      products: this.edit,
      selleceted: [],
    };
  },
  validations: {
    priceRange: {
      price_from: { required },
      price_to: { required },
    },
    idRange: {
      id_from: { required },
      id_to: { required },
    },
  },
  mounted() {
    let page = 1;
    this.getProducts(page);
    this.getBrandsByList();
  },
  methods: {
    getSelected() {
      this.$emit('toParent', this.selleceted)
    },
    clickCallback: function (page) {
      this.getProducts(page);
    },
    async getProducts(page) {
      
      const { data } = await catalogApi.getProducts(page);
      this.products = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
    },
    isPageActive(product) {
      return this.current_page === product;
    },
    onClickPage(product) {
      this.getProducts(product);
    },
    deleteProduct(id, page) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          
          const response = catalogApi.deleteProduct(id).then((response) => {
            
            if (response.data.code == 200) {
              this.getProducts(page);
              if (result.value) {
                this.$swal("Deleted!", "Your file has been deleted.", "success");
              }
            }
          });
        }
      });
    },
    async onToggleChange(id, event) {
      if (event === true) {
        event = 1;
      } else {
        event = 0;
      }

      this.body = { active: event };
      
      const data = await catalogApi.updateProduct(id, {
        ...this.body,
      });
      
      this.getProducts();
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
    },
    async getBrandsByList() {
      const { data } = await catalogApi.getBrandsByList();
      this.brandsList = data.data.data;
    },
    async downloadFile() {
      
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var exportParam = new URLSearchParams(parameter).toString();

      const { data } = await catalogApi.productExport(exportParam);
      const blob = new Blob([data.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.setAttribute("download", "products.xlsx");
      link.href = URL.createObjectURL(blob);
      link.click();
      
    },
    async sortByBrands(page) {
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var brandParam = new URLSearchParams(parameter).toString();
      page = 1;
      
      const { data } = await catalogApi.sortByBrands(brandParam, page);
      this.products = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
    },
    async sortByStatus(page) {
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var statusParam = new URLSearchParams(parameter).toString();

      page = 1;
      
      const { data } = await catalogApi.sortByStatus(statusParam, page);
      this.products = data.data.data.data;
      
    },
    async sortByPriceRange(page) {
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var rangeParam = new URLSearchParams(parameter).toString();

      page = 1;
      
      const { data } = await catalogApi.priceRange(rangeParam, page);
      this.products = data.data.data.data;
      
    },
    async sortByIdRange(page) {
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var idParam = new URLSearchParams(parameter).toString();
      page = 1;
      
      const { data } = await catalogApi.idRange(idParam, page);
      this.products = data.data.data.data;
      
    },

    async sortByBrands(page) {
      var parameter = {
        active: this.status,
        brand: this.brand,
        price_from: this.priceRange.price_from,
        price_to: this.priceRange.price_to,
        id_from: this.idRange.id_from,
        id_to: this.idRange.id_to,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var brandParam = new URLSearchParams(parameter).toString();
      page = 1;
      
      const { data } = await catalogApi.sortByBrands(brandParam, page);
      this.products = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
    },

    async sort(s, page) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;

      var parameter = {
        name: this.searchKey,
        sort_column: this.sortBy,
        sort_type: this.sortDirection,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var sortParam = new URLSearchParams(parameter).toString();
      page = 1;
      
      const { data } = await catalogApi.sortDirection(sortParam, page);
      this.products = data.data.data.data;
      
      this.totalPage = data.data.data.last_page;
      this.current_page = data.data.data.current_page;
    },
    async search(page) {
      var parameter = {
        name: this.searchKey,
      };
      var text = Object.keys(parameter).forEach(
        (k) => (parameter[k] === undefined || parameter[k] === "") && delete parameter[k]
      );
      var searchParam = new URLSearchParams(parameter).toString();
      page = 1;

      
      const { data } = await catalogApi.productSearch(searchParam, page);
      this.products = data.data.data.data;
      
    },
  },
};
</script>
<style scoped>
.productsOffer {
  display: flex;
  justify-content: center;
}

.defult {
  background-image: url(../../../assets/images/download.svg);
  cursor: pointer;
  background-position: right 0.46875rem center;
  background-repeat: no-repeat;
  background-size: 0.65em 1em;
}

.desc {
  background-image: url(../../../assets/images/desc.svg);
}

.asc {
  background-image: url(../../../assets/images/asc.svg);
}

.table th,
.table td {
  border-right: 1px solid #f6f6f7;
}
</style>
